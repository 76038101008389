<template>
  <div>
    <div class="subHeader base noFlex">
      <!-- <div class="chartBox">
        <div class="module-header">
          <h2>매출 현황</h2>
          <div class="dateSelect">
            <input type="text" v-model="year" placeholder="년" />
            <input type="text" v-model="month" placeholder="월" />
            <button type="button" @click="getSalesData()">data가져오기</button>
          </div>
        </div>
        <div id="chart">
          <VueApexCharts
            ref="linechart"
            type="line"
            height="300"
            width="100%"
            align="center"
            :options="chartOptions"
            :series="series"
          ></VueApexCharts>
        </div>
      </div> -->
      <div class="module-header">
        <h2>결제 관리</h2>
      </div>
      <div class="contWrap flex">
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 지난달 결제 금액
          </h4>
          <h3>
            {{ totalUser }}
            <span>원</span>
          </h3>
        </div>
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 이번달 결제 금액
          </h4>
          <h3>
            {{ dayJoinUser }}
            <span>원</span>
          </h3>
        </div>
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 총 발급 포인트
          </h4>
          <h3>
            {{ totalFunding }}
            <span>원</span>
          </h3>
        </div>
        <div class="cont">
          <h4>
            <span class="material-icons">account_circle</span> 이번달 환불금액
          </h4>
          <h3>
            {{ liveFunding }}
            <span>원</span>
          </h3>
        </div>
      </div>
      <div class="wtBox">
        <div class="module-filter">
          <div class="module-button">
            <select v-model="payment_status">
              <option disabled value>구분</option>
              <option value="all">전체</option>
              <option value="ready">미결제</option>
              <option value="cancelled">결제취소</option>
              <option value="paid">결제완료</option>
              <option value="failed">결제실패</option>
            </select>
          </div>
          <!-- <div class="box">
            <div style="width: 80%">
              <Datepicker
                v-model="startYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="시작일"
              ></Datepicker>
            </div>
            <i class="material-icons">calendar_today</i>
          </div>
          <div class="box">
            <div style="width: 80%">
              <Datepicker
                v-model="endYmd"
                :language="ko"
                :format="customFormatter"
                placeholder="종료일"
              ></Datepicker>
            </div>
            <i class="material-icons">calendar_today</i>
          </div> -->

          <div class="search">
            <fieldset>
              <legend>검색</legend>
              <input
                type="search"
                placeholder="검색어를 입력하세요"
                v-model="searchWord"
              />
              <button type="submit" class="material-icons">search</button>
            </fieldset>
          </div>
          <div class="module-button">
            <button type="button" class="blue">검색</button>
          </div>
          <button type="button" class="icoTxt" @click="reload">
            <i class="material-icons">replay</i>
            <b>새로고침</b>
          </button>
        </div>
      </div>

      <div class="module-config">
        <div class="wtBox config-list">
          <header class="flexL">
            <h3>결제내역 리스트</h3>
          </header>
          <table class="module-table lineRow">
            <tr>
              <th scope="col">번호</th>
              <th scope="col">결제날짜</th>
              <th scope="col">결제금액</th>
              <th scope="col">주문명</th>
              <th scope="col">구매자</th>
              <th scope="col">상태</th>
              <th scope="col">상세보기</th>
            </tr>

            <!-- <tr v-for="(item, index) in payementList" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.merchant_uid }}</td>
              <td>{{ dateFormat(item.created_at) }}</td>
              <td>{{ comma(item.paid_amount) }} 원</td>
              <td>
                <span v-for="(product, j) in item.productInfo" :key="j">
                  {{ product.productId.productName }}
                </span>
              </td>
              <td>{{ item.buyer_name }}</td>
              <td>{{ item.buyer_email }}</td>
              <td>
                {{
                  item.pay_method == "card"
                    ? "카드"
                    : item.pay_method == "vbank"
                    ? "가상계좌"
                    : item.pay_method == "trans"
                    ? "계좌이체"
                    : ""
                }}
              </td>
              <td
                :class="{
                  blue: item.status == 'PAID' ? true : false,
                  red: item.status == 'READY' ? true : false,
                  orange: item.status == 'REFUNDED' ? true : false,
                  orange: item.status == 'PAID_FAIL' ? true : false,
                }"
              >
                {{
                  item.status == "READY"
                    ? "미결제"
                    : item.status == "PAID"
                    ? "결제완료"
                    : item.status == "REFUNDED"
                    ? "환불완료"
                    : item.status == "PAID_FAIL"
                    ? "결제실패"
                    : ""
                }}
              </td>
              <td class="flex">
                <button
                  type="button"
                  class="btnView"
                  @click="handleMenu(item.imp_uid)"
                >
                  상세보기
                </button>
                <button
                  v-if="item.isRefund == true && item.status != 'REFUNDED'"
                  type="button"
                  style="margin-left: 5px"
                  class="btnView red"
                  @click="confirmRefund(item.merchant_uid)"
                >
                  환불 요청
                </button>
                <button
                  v-else-if="item.status == 'REFUNDED'"
                  type="button"
                  style="margin-left: 5px"
                  class="btnView red"
                >
                  환불 완료
                </button>
                <button
                  v-else-if="item.status == 'READY'"
                  type="button"
                  style="margin-left: 5px"
                  class="btnView blue"
                >
                  결제 대기
                </button>
                <button
                  v-else-if="item.status == 'PAID' && item.isMaking == false"
                  type="button"
                  style="margin-left: 5px"
                  class="btnView blue"
                  @click="doOrder(item.merchant_uid)"
                >
                  발주 하기
                </button>
                <button
                  v-else-if="item.isMaking == true"
                  type="button"
                  style="margin-left: 5px"
                  class="btnView blue"
                >
                  발주 완료
                </button>
              </td>
            </tr> -->
          </table>

          <div class="pagination" v-if="total > 0">
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="10"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
// import VueApexCharts from "vue-apexcharts";
import { fetchSales } from "@/api/index";
import { ko } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    // VueApexCharts,
  },
  data() {
    return {
      ko: ko,
      year: "",
      month: "",
      series: [
        {
          name: "Sales",
          data: [], // 실제 보여지는 값
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
          //   colors: "#000000",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [], // x 축 값
        },
      },
      startYmd: "",
      endYmd: "",
      payment_status: "all",
      searchWord: "",
    };
  },
  methods: {
    reload() {
      this.currentPage = 1;
      this.searchWord = "";
      this.getUserList();
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSalesData();
    },
    getSalesData() {
      let params = {
        year: this.year,
        month: this.month - 1,
      };

      if (params.year == 0) {
        return alert("연도를 입력해주세요.");
      } else {
        fetchSales(params).then((res) => {
          this.series[0].data = [];
          this.chartOptions.xaxis.categories = [];
          let sales = res.data.sales;
          // this.xaxis.categories =

          for (var i = 0; i < sales.length; i++) {
            this.series[0].data.push(sales[i].sum);
            if (sales[i]._id.group.day) {
              this.chartOptions.xaxis.categories.push(sales[i]._id.group.day);
            } else {
              this.chartOptions.xaxis.categories.push(sales[i]._id.group.month);
            }
          }
          this.$refs.linechart.updateSeries(
            [
              {
                data: this.series[0].data,
              },
            ],
            false,
            true
          );
          this.$refs.linechart.updateOptions(
            {
              xaxis: {
                categories: this.chartOptions.xaxis.categories,
              },
            },
            true
          );
        });
      }
    },
  },
};
</script>
